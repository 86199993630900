import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import {
  FormEvent,
  Key,
  MouseEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { AppContext } from 'doctors/context/app'
import useFormie from 'doctors/hooks/use-formie'
import useMediaQuery from 'doctors/hooks/use-media-query'
import useSettings from 'doctors/hooks/use-settings'
import { AButton, MModal } from 'ui'
import SvgCheckTrueIcon from 'ui/svg/icons/fill/check-true.svg'
import SvgCheckFalseIcon from 'ui/svg/icons/fill/check-false.svg'
import { AFormInput } from 'doctors/components/atoms/a-form-input'
import { AFormTextarea } from 'doctors/components/atoms/a-form-textarea'
import { MContactFormModalItem } from 'doctors/components/molecules/m-contact-form-modal-item'
import * as CONST from 'ui/constants/index'
import {getIsTestEmail, useSiteInfo} from 'shared'

type Props = {
  data: any
  open: boolean
  onClose: Function
}

export const CContactFormModal = ({ data, open, onClose }: Props) => {
  const appContext = useContext(AppContext)
  const formie = useFormie()
  const siteInfo = useSiteInfo()
  const router = useRouter()
  const settings = useSettings()
  const { t } = useTranslation('common')

  const matches = useMediaQuery('(min-width: 1280px)')

  const formRef = useRef<HTMLFormElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)

  const [ids, setIds] = useState<any>({})
  const [step, setStep] = useState<number>(1)

  const [errors, setErrors] = useState<any>(undefined)
  const [errorCount, setErrorCount] = useState<number>(0)

  const [lastName, setLastName] = useState<string>(data.lastName)
  const [email, setEmail] = useState<string>(data.email)
  const [phone, setPhone] = useState<string>(data.phone)
  const [message, setMessage] = useState<string>(data.message)
  const [isSubscribed, setIsSubscribed] = useState<boolean>(data.isSubscribed)

  const SvgCheckIcon = isSubscribed ? SvgCheckTrueIcon : SvgCheckFalseIcon

  const doctorsAvailable = useMemo(() => {
    return appContext?.state?.doctors?.filter((item) => {
      return item.slug !== router.query.slug
    })
  }, [appContext?.state?.doctors])

  const doctorsSelected = useMemo(() => {
    return appContext?.state?.doctors?.filter((item) => {
      return ids[item.id]
    })
  }, [appContext?.state?.doctors, ids])

  const doctorsUnavailable = useMemo(() => {
    return appContext?.state?.doctors?.filter((item) => {
      return item.slug === router.query.slug
    })
  }, [appContext?.state?.doctors])

  const goToStep = (newStep: number) => (event?: MouseEvent<any>) => {
    event?.preventDefault()

    setStep(newStep)
  }

  const toggleId = (item: any) => {
    setIds({ ...ids, [item.id]: !ids[item.id] })

    if (ids[item.id]) {
      const location = appContext?.state?.locations?.find(
        (v) => v.title === item.subtitle
      )
      pushSegment('Additional Doctor Contact Selections', {
        selected_doctor_name: item.title,
        specialist_doctor: item.specialist,
        doctor_distance: item.distance,
        doctor_clinic: location?.title,
        doctor_address: item.address,
        suggested_doctor_count: doctorsAvailable?.length,
      })
    }
  }

  const closeModal = (event?: MouseEvent<any>) => {
    event?.preventDefault()

    onClose()
    setIds({})
    setStep(1)
  }

  const closeModalWithRedirect = (event?: MouseEvent<any>) => {
    closeModal(event)

    const q = appContext?.state.query.q ?? ''
    const type = appContext?.state.query.type || settings.types.default
    const radius = appContext?.state.query.radius || settings.radius.default
    const sort = appContext?.state.query.sort || settings.sort.default

    const queryParams =
      settings.search === 'geocoding'
        ? new URLSearchParams({ q, radius, sort, type })
        : new URLSearchParams({ q, type })

    router.push(`/?${queryParams.toString()}`, undefined, { shallow: true })
  }

  const pushSegment = (event: string, data: any) => {
    if (!window.analytics || siteInfo?.iso !== 'en-US') {
      return
    }
    window.analytics.track(event, data)
  }

  const handleAutopilot = async () => {
    const isTestEmail = getIsTestEmail(email)
    if (!isSubscribed || siteInfo?.iso !== 'en-US' || isTestEmail) {
      return
    }

    const body = {
      contact: {
        Email: email,
        custom: {
          'integer--Multiple--Doctor--Contacted--Count':
            doctorsSelected?.length,
        },
      },
    }

    fetch(
      process.env.NEXT_PUBLIC_DOCTORS_API_PROXY_URL +
        '/autopilot/captureUser.js',
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }
    ).catch(() => {})
  }

  const submitForm = async (event?: FormEvent<any> | MouseEvent<any>) => {
    event?.preventDefault()
    setStep(3)
    setErrorCount(0)

    if (doctorsSelected) {
      for (const doctor of doctorsSelected) {
        const location = appContext?.state.locations.find(
          (v) => v.title === doctor.subtitle
        )

        const res = await formie.send({
          doctor,
          location,
          lastName,
          email,
          phone,
          message,
        })

        if (!res.success) {
          setErrors(res.errors)
          setErrorCount(errorCount + 1)
        }

        pushSegment('Additional Doctor Contact Submission', {
          selected_doctor_name: doctor.title,
          specialist_doctor: doctor.specialist,
          doctor_distance: doctor.distance,
        })
      }
    }

    handleAutopilot()

    if (doctorsSelected?.length === errorCount) {
      setStep(2)
    } else {
      setErrors(undefined)
      setStep(4)
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      closeModal()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    setLastName(data.lastName)
    setEmail(data.email)
    setPhone(data.phone)
    setMessage(data.message)
    setIsSubscribed(data.isSubscribed)
  }, [data])

  useEffect(() => {
    setTimeout(() => {
      modalRef.current?.parentElement?.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100)
  }, [step])

  return (
    <MModal open={open} onClose={closeModal}>
      <div ref={modalRef}>
        {step === 1 && (
          <>
            <div className="font-body font-light text-3xl md:text-4xl text-center">
              {t('select-from-the-list-below')}
            </div>

            <div className="scroll-y w-full mt-4">
              {doctorsAvailable?.map((item) => (
                <MContactFormModalItem
                  key={item.id}
                  callback={toggleId}
                  disabled={false}
                  selected={!!ids[item.id]}
                  item={item}
                  size={matches ? 'large' : 'small'}
                />
              ))}
            </div>

            <div className="w-full mt-4 bg-primary-teal font-body font-light py-1 text-center text-sm text-white">
              {t('message-already-sent-to')}
            </div>

            <div className="w-full">
              {doctorsUnavailable?.map((item) => (
                <MContactFormModalItem
                  key={item.id}
                  disabled={true}
                  selected={true}
                  item={item}
                  size={matches ? 'large' : 'small'}
                />
              ))}
            </div>

            <div className="flex flex-col lg:flex-row items-start mt-8 lg:gap-4">
              <AButton
                className={classNames('max-w-md w-full lg:w-auto', {
                  'opacity-50 pointer-events-none': !doctorsSelected?.length,
                })}
                size="lg"
                title={t('done') ?? ''}
                type="button"
                onClick={goToStep(2)}
              >
                <>{t('done')}</>
              </AButton>
              <AButton
                className="mt-4 lg:mt-0 max-w-md w-full lg:w-auto"
                color="black"
                size="lg"
                style="outline"
                title={t('cancel') ?? ''}
                type="button"
                onClick={closeModal}
              >
                <>{t('cancel')}</>
              </AButton>
            </div>
          </>
        )}

        {(step === 2 || step === 3) && (
          <div
            className={classNames('transition-opacity duration-200 ease-out', {
              'opacity-50 pointer-events-none': step === 3,
            })}
          >
            <div className="mx-4 lg:mx-0 font-body font-light text-3xl md:text-4xl text-center">
              {t('contact-additional-doctors')}
            </div>

            <div className="mx-4 lg:mx-0 font-body font-light text-center">
              {t('form-info')}
            </div>

            {errors?.message?.map((message: string) => (
              <div className="mx-4 lg:mx-0 font-body font-light text-center text-secondary-red mt-2">
                {t(message)}
              </div>
            ))}

            <form className="mx-4 lg:mx-0" onSubmit={submitForm} ref={formRef}>
              <div className="flex flex-wrap -mx-3">
                <AFormInput
                  placeholder={t('full-name') ?? ''}
                  required={true}
                  value={lastName}
                  version="full"
                  onChangeHandler={setLastName}
                />
                <AFormInput
                  placeholder={t('email-address') ?? ''}
                  required={true}
                  type="email"
                  value={email}
                  version="half"
                  onChangeHandler={setEmail}
                />
                <AFormInput
                  placeholder={t('phone-number') ?? ''}
                  required={true}
                  value={phone}
                  version="half"
                  onChangeHandler={setPhone}
                />
                <AFormTextarea
                  placeholder={t('message') ?? ''}
                  value={message}
                  onChangeHandler={setMessage}
                />
              </div>
            </form>

            <div className="w-full max-h-30 mt-4 overflow-y-auto">
              {doctorsSelected?.map((item: { id: Key | null | undefined }) => (
                <MContactFormModalItem
                  key={item.id}
                  disabled={false}
                  selected={true}
                  item={item}
                  size={'small'}
                />
              ))}
            </div>

            <div className="w-full mt-4 bg-primary-teal font-body font-light py-1 text-center text-sm text-white">
              {t('message-already-sent-to')}
            </div>

            <div className="w-full">
              {doctorsUnavailable?.map((item) => (
                <MContactFormModalItem
                  key={item.id}
                  disabled={true}
                  selected={true}
                  item={item}
                  size={'small'}
                />
              ))}
            </div>

            <div className="mt-4 text-center">
              <a
                className={classNames('font-body font-bold text-sm uppercase', {
                  'text-primary-teal':
                    siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                  'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                })}
                href="#"
                onClick={goToStep(1)}
              >
                {t('add-another-doctor')}
              </a>
            </div>

            <div
              className="bg-gray-300 cursor-pointer flex mt-10 p-5"
              onClick={() => setIsSubscribed(!isSubscribed)}
            >
              <SvgCheckIcon className="flex-shrink-0 fill-current mr-4 text-primary-teal w-6 h-6" />
              <p
                className="text-xs text-gray-700 w-full [&>a]:underline [&>a]:text-primary-dark-teal"
                dangerouslySetInnerHTML={{
                  __html: t('form-subscription-text') ?? '',
                }}
              />
            </div>

            <div className="flex flex-col lg:flex-row lg:gap-4 items-center mt-8">
              <AButton
                className={classNames('max-w-md w-full lg:w-auto', {
                  'opacity-50 pointer-events-none':
                    !lastName || !email || !phone,
                })}
                size="lg"
                title={t('submit') ?? ''}
                type="button"
                onClick={submitForm}
              >
                <>{t('submit')}</>
              </AButton>
              <AButton
                className="mt-4 lg:mt-0 max-w-md w-full lg:w-auto"
                color="black"
                size="lg"
                style="outline"
                title={t('cancel') ?? ''}
                type="button"
                onClick={closeModal}
              >
                <>{t('cancel')}</>
              </AButton>
            </div>

            <div className="text-sm text-gray-700 font-semibold leading-4 mt-8 text-center lg:text-left">
              {t('form-data-provide')}
              <br />
              {t('staar-more-info')}{' '}
              <a
                href={t('privacy-policy-url') ?? ''}
                className="text-primary-teal hover:text-primary-dark-teal"
              >
                {t('privacy-policy')}
              </a>{' '}
              {t('and')}{' '}
              <a
                href={t('terms-of-use-url') ?? ''}
                className="text-primary-teal hover:text-primary-dark-teal"
              >
                {t('terms-of-use')}
              </a>
              .
            </div>
          </div>
        )}

        {step === 4 && (
          <div className="flex flex-col items-center px-4 lg:px-0">
            <div
              className={classNames(
                'font-body font-light text-3xl md:text-4xl text-center',
                {
                  'text-primary-teal':
                    siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                  'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                }
              )}
            >
              {t('your-message-has-been-sent')}
            </div>

            <div className="text-base font-semibold leading-7 text-gray-400 text-center lg:text-left">
              {t('message-submitted-info')}{' '}
              <span className="text-gray-800">
                {doctorsUnavailable && doctorsUnavailable[0]?.title}
              </span>{' '}
              {t('and')}{' '}
              <span className="text-gray-800">
                {doctorsSelected && doctorsSelected.length - errorCount}{' '}
                {t('others')}.
              </span>
            </div>

            <AButton
              className="mt-4 max-w-md w-full lg:w-auto"
              color="black"
              size="lg"
              style="outline"
              title={t('back-to-results') ?? ''}
              type="button"
              onClick={closeModalWithRedirect}
            >
              <>{t('back-to-results')}</>
            </AButton>
          </div>
        )}
      </div>
    </MModal>
  )
}
