import classNames from 'classnames'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { Children, isValidElement, MouseEvent, ReactNode, useMemo } from 'react'
import css from 'styled-jsx/css'
import { AButton, AHyperlink, ASvg } from 'ui'
import TagManager from 'react-gtm-module'
import SvgArrowUpIcon from 'ui/svg/icons/stroke/arrow-up.svg'
import SvgPhoneIcon from 'ui/svg/icons/fill/phone.svg'
import SvgWebsiteIcon from 'ui/svg/icons/fill/website.svg'
import * as CONST from 'ui/constants/index'
import SvgLocationIconUS from 'ui/svg/icons/fill/location-alt-us.svg'
import { useSiteInfo } from 'shared'
import { CraftDoctor, CraftLocation } from '../../craft-types'
import { ADetailsAvatar } from '../atoms/a-details-avatar'
import { MOfficeHours } from '../molecules/m-office-hours'
import { MReviews } from '../molecules/m-reviews'
import useSettings from '../../hooks/use-settings'
import { AInlineVideo } from '../atoms/a-inline-video'
import { CGallery } from './c-gallery'
import { CInformation } from './c-information'

type Props = {
  doctor?: CraftDoctor
  name: string
  photo?: string
  specialist?: boolean
  location?: CraftLocation
  distance?: number
  hours?: Array<any>
  onScrollToTop?: () => void
  onScrollToTabs?: () => void
  children?: ReactNode
  biography?: string | undefined
  certificates?: string[]
  education?: string[]
  languages?: string[]
  displayContact?: boolean
  contactForm?: ReactNode
  website?: string
}

const defaultProps = {
  doctor: {},
  photo: null,
  specialist: false,
  hours: [],
}

const CDetails = ({
  doctor,
  name,
  photo,
  specialist,
  location,
  hours,
  onScrollToTop,
  onScrollToTabs,
  children,
  distance,
  biography,
  certificates,
  education,
  languages,
  displayContact = false,
  contactForm,
  website,
}: Props) => {
  const router = useRouter()
  const settings = useSettings()
  const { t } = useTranslation('common')
  const siteInfo = useSiteInfo()

  const urlData = useMemo(() => {
    const matches = router.asPath.match(
      /^\/(?<type>doctors|locations)\/(?<slug>[^/]+)\/(?<tab>[^?^/]+)/
    )

    if (
      matches?.groups?.type &&
      matches?.groups?.slug &&
      matches?.groups?.tab
    ) {
      return {
        type: matches.groups.type,
        slug: decodeURI(matches.groups.slug),
        tab: matches.groups.tab,
      }
    }

    return {
      type: null,
      slug: null,
      tab: null,
    }
  }, [router.asPath])

  const doctorEmail = useMemo(() => {
    return doctor && doctor.id
      ? !!doctor.doctorEmail
      : location?.locationDoctors?.some(
          (doctor: CraftDoctor) => doctor.doctorEmail
        )
  }, [doctor])

  const visibleButtons = useMemo(() => {
    return true // !['contact', 'doctors', 'locations'].includes(urlData.tab ?? '')
  }, [urlData])

  const visibleTabs = useMemo(() => {
    const tabs: Array<ReactNode> = []

    Children.forEach(children, (child) => {
      if (isValidElement(child) && child.props.title && child.props.handle) {
        tabs.push(child)
      }
    })
    return tabs
  }, [children])

  const formattedPhoneNumber = useMemo(() => {
    return location?.locationPhone?.replace('tel:', '') ?? ''
  }, [location])

  const doctorData = useMemo(() => {
    return {
      doctor_name: name,
      specialist_doctor: specialist,
      doctor_clinic: location?.title,
      doctor_address: location?.locationAddress,
      review_type: doctor?.doctorReviewType,
      review_available: !!doctor?.doctorReviewId,
    }
  }, [doctor, location])

  const locationData = useMemo(() => {
    return {
      clinic_name: location?.title,
      clinic_address: location?.locationAddress,
      review_type: location?.locationReviewType,
      review_available: !!location?.locationReviewId,
    }
  }, [location])

  const namedRoute = (tab: string) => {
    return `/${urlData.type}/${urlData.slug}/${tab}`
  }

  const pushSegment = (event: string, data: any, callback?: Function) => {
    if (!window.analytics || siteInfo?.iso !== 'en-US') {
      if (callback instanceof Function) {
        callback()
      }
      return
    }

    window.analytics.track(event, data, callback)
  }

  const handleContactButton = (link: string) => {
    if (urlData.type === 'doctors') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'doctorOrLocationIntention',
          event_category: 'Doctor or Location Contact Intention',
          event_action: 'Doctor',
          event_label: doctor?.title,
        },
      })
      pushSegment('Contact Doctor', doctorData)
    } else if (urlData.type === 'locations') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'doctorOrLocationIntention',
          event_category: 'Doctor or Location Contact Intention',
          event_action: 'Location',
          event_label: location?.title,
        },
      })
      pushSegment('Location Contact Intention', locationData)
    }

    if (link) {
      const event = new CustomEvent('showExternalModal', {
        detail: link,
      })
      window.dispatchEvent(event)
    } else {
      router.push(namedRoute('contact'), undefined, { shallow: true })
      setTimeout(() => {
        onScrollToTabs && onScrollToTabs()
      }, 100)
    }
  }

  const handlePhoneButton = (event: MouseEvent) => {
    event.preventDefault()
    const callback = () => {
      window.location.href = location?.locationPhone ?? ''
    }
    if (urlData.type === 'doctors') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'call_doctorOrLocation',
          doctor_name: doctor?.title,
          doctor_distance: distance,
          doctor_id: doctor?.id,
        },
      })
      pushSegment('Doctor Called', doctorData, callback)
    } else if (urlData.type === 'locations') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'call_doctorOrLocation',
          location_name: location?.title,
          location_distance: distance,
          location_id: location?.id,
        },
      })
      pushSegment('Location Called', locationData, callback)
    }
  }

  const handleWebsiteButton = (event: MouseEvent, link: string) => {
    event.preventDefault()

    if (urlData.type === 'doctors') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'redirectionToExtWebsite',
          event_category: 'Doctor or Location Website Redirection',
          event_action: 'Doctor',
          event_label: doctor?.title,
        },
      })
      pushSegment('Doctor Website Visited', {
        ...doctorData,
        clicked_url: location?.locationWebsite,
      })
    } else if (urlData.type === 'locations') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'redirectionToExtWebsite',
          event_category: 'Doctor or Location Website Redirection',
          event_action: 'Loaction',
          event_label: location?.title,
        },
      })
      pushSegment('Location Website Visited', {
        ...locationData,
        clicked_url: location?.locationWebsite,
      })
    }

    if (link) {
      const event = new CustomEvent('showExternalModal', {
        detail: link,
      })
      window.dispatchEvent(event)
    }
  }

  const handleDirectionsButton = () => {
    if (urlData.type === 'doctors') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'getDirectionsClicks',
          event_category: 'Get Direction Clicks',
          event_action: 'Doctor',
          event_label: doctor?.title,
        },
      })
      pushSegment('Doctor Directions', doctorData)
    } else if (urlData.type === 'locations') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'getDirectionsClicks',
          event_category: 'Get Direction Clicks',
          event_action: 'Location',
          event_label: location?.title,
        },
      })
      pushSegment('Location Directions', locationData)
    }
  }

  const handleBackToTop = () => {
    if (onScrollToTop instanceof Function) {
      onScrollToTop()
    }
  }

  if (siteInfo.isUS) {
    return (
      <div className="pb-[60px] lg:pb-0">
        <div
          className={classNames(
            'flex flex-col items-center gap-4 mx-7 bg-neutral-50 py-6 px-5 mt-14 md:mt-9 md:mx-20',
            {
              'mb-14 md:mb-9': displayContact,
            }
          )}
        >
          <h1 className="text-center text-3xl font-semibold">{name}</h1>
          <MReviews
            doctorCustomReviewLink={doctor?.doctorCustomReviewLink}
            doctorCustomReviewText={doctor?.doctorCustomReviewText}
            doctorGoogleReviewLink={doctor?.doctorGoogleReviewLink}
            doctorReviewId={doctor?.doctorReviewId}
            doctorReviewType={doctor?.doctorReviewType}
            location={location}
            isDetail
          />
          <div className="flex justify-center">
            <ADetailsAvatar avatar={photo} specialist={specialist} />
          </div>
          {(location?.locationEmail ||
            location?.locationContact ||
            doctorEmail) && (
            <div className="flex flex-col gap-2">
              {!displayContact ? (
                <>
                  <AButton
                    type="button"
                    className="max-w-[280px] w-full"
                    title={t('set-consultation') ?? ''}
                    size="lg"
                    onClick={() =>
                      handleContactButton(location?.locationContact ?? '')
                    }
                  >
                    <span>{t('set-consultation')}</span>
                  </AButton>
                  {location?.locationPhone && (
                    <AButton
                      type="button"
                      className="max-w-[280px] w-full"
                      title={
                        t('call-office', { phone: formattedPhoneNumber }) ??
                        formattedPhoneNumber
                      }
                      size="lg"
                      onClick={(e) => handlePhoneButton(e)}
                    >
                      <span>{formattedPhoneNumber}</span>
                    </AButton>
                  )}
                </>
              ) : (
                <Link href={namedRoute('about')}>{t('back-to-profile')}</Link>
              )}
            </div>
          )}
          {displayContact && contactForm}
        </div>
        {!displayContact && (
          <div className="flex flex-col gap-[26px] mx-7 mt-8">
            <div className="border-b border-neutral-300 pb-8 lg:px-12">
              <h3 className="uppercase font-semibold text-primary-dark-teal mb-4">
                {t('office-information')}
              </h3>
              <div className="grid grid-cols-[1fr] lg:grid-cols-[1fr_0.7fr] gap-5">
                <div>
                  <h4 className="font-semibold mb-1.5">{location?.title}</h4>
                  <div className="flex items-center gap-x-2.5 mb-2">
                    <ASvg
                      className="w-5 h-5 text-teal stroke-current"
                      svg={SvgLocationIconUS}
                    />
                    <address className="not-italic font-light">
                      {distance || '< 1'} {settings.unit.label}
                    </address>
                  </div>
                  <address className="not-italic font-light">
                    {location?.locationAddress}
                  </address>
                  {website && (
                    <a
                      href={website}
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold underline text-primary-dark-teal block mt-2"
                      onClick={(e) => handleWebsiteButton(e, website ?? '')}
                    >
                      {t('visit-website')}
                    </a>
                  )}
                </div>
                <div>
                  <h4 className="font-semibold mb-2">{t('office-hours')}</h4>
                  {!!hours && <MOfficeHours hours={hours} />}
                </div>
              </div>
            </div>
            {biography && (
              <div className="border-b border-neutral-300 pb-8 lg:px-12">
                <i className="block mb-4 text-sm">{t('doctor-disclaimer')}</i>
                <h3 className="uppercase font-semibold text-primary-dark-teal mb-4">
                  {t('about')}
                </h3>
                <div
                  className="flex flex-col gap-5 text-sm font-light"
                  dangerouslySetInnerHTML={{
                    __html: biography,
                  }}
                />
                {!!doctor?.doctorVideo?.length && (
                  <AInlineVideo
                    code={doctor.doctorVideo[0].embeddedAsset.code}
                  />
                )}
                {!!doctor?.doctorGallery?.length && (
                  <>
                    <h3 className="uppercase font-semibold text-primary-dark-teal mb-4 mt-10">
                      {t('photos-of')} {doctor.title}
                    </h3>
                    <CGallery className="mt-4" images={doctor.doctorGallery} />
                  </>
                )}
              </div>
            )}
            <div className="border-b border-neutral-300 pb-8 lg:px-12">
              <CInformation
                name={doctor?.title ?? ''}
                rows={[
                  [
                    {
                      label: t('languges-spoken'),
                      type: 'list',
                      items: languages ?? [],
                    },
                  ],
                  [
                    {
                      label: t('certification-and-association'),
                      type: 'list',
                      items: certificates ?? [],
                    },
                  ],
                  [
                    {
                      label: t('education'),
                      type: 'list',
                      items: education ?? [],
                    },
                  ],
                ]}
                doctor={doctor}
              />
            </div>
            {location?.locationPhone && (
              <div className="pb-8 lg:px-12 flex flex-col items-center">
                <p className="text-center mb-5">{t('call-today')}</p>
                <AButton
                  type="a"
                  className="max-w-[280px] w-full"
                  title={t('phone') ?? ''}
                  size="lg"
                  onClick={(e) => handlePhoneButton(e)}
                >
                  <span>{formattedPhoneNumber}</span>
                </AButton>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="flex w-full h-full font-body flex-col lg:flex-row">
      <div className="w-full lg:max-w-[390px] border-r border-gray-200 flex-shrink-0 z-40">
        <div className="mt-24 lg:-mt-[77px] flex justify-center">
          <ADetailsAvatar avatar={photo} specialist={specialist} />
        </div>
        <h2
          className={classNames(
            'lg:hidden font-body font-light mt-4 text-3xl md:text-4xl text-center',
            {
              'text-primary-teal': siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
              'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
            }
          )}
        >
          {name}
        </h2>
        {visibleButtons && (
          <div className="lg:hidden flex mt-6 px-8 flex-col w-full items-center">
            {(location?.locationEmail ||
              location?.locationContact ||
              doctorEmail) && (
              <AButton
                type="button"
                className="max-w-md w-full"
                title={t('contact') ?? ''}
                size="lg"
                onClick={() =>
                  handleContactButton(location?.locationContact ?? '')
                }
              >
                <span>{t('contact')}</span>
              </AButton>
            )}
            {location?.locationPhone && (
              <AButton
                type="a"
                href={location.locationPhone}
                className="mt-4 max-w-md w-full"
                icon={SvgPhoneIcon}
                iconClass="w-5 h-5 fill-current"
                iconAlign="left"
                size="lg"
                title={t('phone') ?? ''}
                style="outline"
                color="black"
                onClick={(e) => handlePhoneButton(e)}
              >
                <span>{formattedPhoneNumber}</span>
              </AButton>
            )}
            {location?.locationWebsite && (
              <AButton
                type="a"
                href={location.locationWebsite}
                className="mt-4 max-w-md w-full"
                icon={SvgWebsiteIcon}
                iconClass="w-5 h-5 fill-current"
                rel="noreferrer"
                iconAlign="left"
                style="outline"
                size="lg"
                color="black"
                onClick={(e) =>
                  handleWebsiteButton(e, location.locationWebsite ?? '')
                }
              >
                <span>{t('website')}</span>
              </AButton>
            )}
          </div>
        )}
        <div className="py-8 px-20">
          <h3 className="text-2xl text-gray-900 font-semibold">
            {t('contact-info')}
          </h3>
          <div className="mt-1 font-base font-semibold text-gray-800 leading-7">
            {location?.title}
          </div>
          <div
            className="mt-1 font-base font-semibold text-gray-800 leading-7"
            dangerouslySetInnerHTML={{
              __html: location?.locationAddress ?? '',
            }}
          />
          <AHyperlink
            className="mt-2 block"
            type="a"
            href={'https://maps.google.com?q=' + location?.locationAddress}
            target="_blank"
            onClick={handleDirectionsButton}
            color={siteInfo?.layout === CONST.SITE_LAYOUT.ICL ? 'teal' : 'pink'}
          >
            <span>{t('get-directions')}</span>
          </AHyperlink>
          <h3 className="text-2xl text-gray-900 font-semibold mt-10">
            {t('office-hours')}
          </h3>
          {!!hours && <MOfficeHours hours={hours} />}
        </div>
        <div className="hidden lg:block">
          {visibleTabs.map((tab) => {
            if (isValidElement(tab)) {
              return (
                <Link
                  key={tab.props.handle}
                  href={namedRoute(tab.props.handle)}
                  prefetch={false}
                  shallow={true}
                  className={classNames(
                    'px-20 block w-full font-semibold text-2xl py-6 border-t border-l-4 border-gray-200 last:border-b transition-colors duration-200 ease-out',
                    {
                      'text-primary-teal border-l-[#10a8ba]':
                        tab.props.active &&
                        siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                      'text-pink-500 border-l-[#f86e83]':
                        tab.props.active &&
                        siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                      'text-[#add8e6] border-transparent':
                        !tab.props.active &&
                        siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                      'text-pink-400 border-transparent':
                        !tab.props.active &&
                        siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                    }
                  )}
                >
                  {tab.props.title}
                </Link>
              )
            } else {
              return <></>
            }
          })}
        </div>
      </div>
      <div className="flex-grow flex flex-col min-w-0 w-full">
        <div
          className={classNames(
            'px-8 lg:px-16 flex flex-col lg:block items-center',
            {
              'lg:py-10': visibleButtons,
            }
          )}
        >
          <h2
            className={classNames(
              'hidden lg:block font-body font-light text-3xl md:text-4xl text-center lg:text-left',
              {
                'pt-10 pb-2': !visibleButtons,
                'text-primary-teal': siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
              }
            )}
          >
            {name}
          </h2>
          {visibleButtons && (
            <div className="hidden lg:flex mt-6 flex-col lg:flex-row w-full items-center">
              {(location?.locationEmail ||
                location?.locationContact ||
                doctorEmail) && (
                <AButton
                  className="lg:mr-4 max-w-md w-full lg:w-auto"
                  type="button"
                  size="lg"
                  color={
                    siteInfo?.layout === CONST.SITE_LAYOUT.ICL ? 'teal' : 'pink'
                  }
                  title={t('contact') ?? ''}
                  onClick={() =>
                    handleContactButton(location?.locationContact ?? '')
                  }
                >
                  <span>{t('contact')}</span>
                </AButton>
              )}
              {location?.locationPhone && (
                <AButton
                  type="a"
                  href={location.locationPhone}
                  size="lg"
                  className="mt-4 lg:mt-0 lg:mr-4 max-w-md w-full lg:w-auto"
                  icon={SvgPhoneIcon}
                  iconClass="w-5 h-5 fill-current"
                  iconAlign="left"
                  title={t('phone') ?? ''}
                  style="outline"
                  color="black"
                  onClick={handlePhoneButton}
                >
                  <span>{formattedPhoneNumber}</span>
                </AButton>
              )}
              {location?.locationWebsite && (
                <AButton
                  size="lg"
                  className="mt-4 lg:mt-0 max-w-md w-full lg:w-auto"
                  icon={SvgWebsiteIcon}
                  iconClass="w-5 h-5 fill-current"
                  iconAlign="left"
                  style="outline"
                  color="black"
                  type="a"
                  href={location.locationWebsite}
                  rel="noreferrer"
                  onClick={(e) =>
                    handleWebsiteButton(e, location.locationWebsite ?? '')
                  }
                >
                  <span>{t('website')}</span>
                </AButton>
              )}
            </div>
          )}
          {urlData.tab === CONST.URL_DATA_TAB.ABOUT && (
            <MReviews doctor={doctor} location={location} />
          )}
        </div>
        <div className="lg:hidden my-8">
          {visibleTabs.map((tab) => {
            if (isValidElement(tab)) {
              return (
                <Link
                  key={tab.props.handle}
                  href={namedRoute(tab.props.handle)}
                  prefetch={false}
                  shallow={true}
                  className={classNames(
                    'px-8 block w-full font-semibold text-2xl py-6 border-t border-gray-200 last:border-b transition-colors duration-200 ease-out',
                    {
                      'text-primary-teal border-l-[#10a8ba]':
                        tab.props.active &&
                        siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                      'text-pink-500 border-l-[#f86e83]':
                        tab.props.active &&
                        siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                      'text-[#add8e6] border-transparent':
                        !tab.props.active &&
                        siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                      'text-pink-400 border-transparent':
                        !tab.props.active &&
                        siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                    }
                  )}
                >
                  {tab.props.title}
                </Link>
              )
            } else {
              return <></>
            }
          })}
        </div>
        <div className="c-details__tabs px-8 lg:px-16">{children}</div>
        <div className="p-8 lg:hidden flex justify-center">
          <AButton
            type="button"
            style="outline"
            className="max-w-md w-full lg:w-auto"
            color="black"
            icon={SvgArrowUpIcon}
            iconClass="stroke-current"
            title={t('scroll-to-top') ?? ''}
            onClick={handleBackToTop}
          >
            <span>{t('back-to-top')}</span>
          </AButton>
        </div>
      </div>
      <style jsx>{styles}</style>
    </div>
  )
}

const styles = css`
  .c-details__tabs::after {
    content: '';
    height: 58px;
    display: block;
    width: 100%;
  }
`

CDetails.defaultProps = defaultProps

export { CDetails }
