import generateUuidv4 from 'doctors/helpers/generate-uuid'
import { SiteInfo } from 'shared'

const formie = (siteInfo: SiteInfo | null) => {
  const url = `/proxy/${siteInfo?.layout ?? ''}`

  const uuid = () => {
    let _uuid = localStorage.getItem('USER_UUID')

    if (!_uuid) {
      _uuid = generateUuidv4()

      localStorage.setItem('USER_UUID', _uuid)
    }

    return _uuid
  }

  const parse = (data: any, appendUserUuid = true) => {
    const formData = new FormData()

    formData.append('handle', 'findADoctor')
    formData.append('siteHandle', siteInfo?.handle ?? '')

    if (data.doctor) {
      formData.append('fields[doctorID]', data.doctor.id)
      formData.append('fields[doctorName]', data.doctor.title)
    }

    if (data.location) {
      formData.append('fields[locationID]', data.location.id)
      formData.append('fields[locationName]', data.location.title)
    }

    formData.append('fields[firstName]', '')
    formData.append('fields[lastName]', data.lastName)
    formData.append('fields[email]', data.email)
    formData.append('fields[cellPhone]', data.phone)
    formData.append('fields[message]', data.message)
    formData.append('fields[siteName]', siteInfo?.name ?? '')

    if (appendUserUuid) {
      formData.append('fields[userUuid]', uuid())
    }

    return formData
  }

  const send = async (data: any, appendUserUuid = true) => {
    try {
      const getForm = await fetch(url + '/info', {
        cache: 'no-cache',
        headers: { accept: 'application/json' },
      })
      const { csrfTokenValue } = await getForm.json()

      const formData = parse(data, appendUserUuid)
      formData.append('CRAFT_CSRF_TOKEN', csrfTokenValue || '')

      const postForm = await fetch(url + '/submit', {
        method: 'post',
        cache: 'no-cache',
        headers: { accept: 'application/json' },
        body: formData,
      })
      const { errors, success } = await postForm.json()

      return { errors, success }
    } catch (e) {
      console.error(e)

      return {
        errors: { message: ['message-unexpected-error'] },
        success: false,
      }
    }
  }

  return { parse, send }
}

export default formie
