import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { MouseEvent, useContext } from 'react'
import css from 'styled-jsx/css'
import { AButton, ASvg } from 'ui'
import TagManager from 'react-gtm-module'
import SvgSpecialistIcon from 'ui/svg/icons/fill/specialist.svg'
import SvgArrowRightIcon from 'ui/svg/icons/stroke/arrow-right.svg'
import * as CONST from 'ui/constants/index'
import SvgLocationIconUS from 'ui/svg/icons/fill/location-alt-us.svg'
import { useSiteInfo } from 'shared'
import { AppContext } from '../../context/app'
import useSettings from '../../hooks/use-settings'
import { MReviews } from './m-reviews'

type Props = {
  expandable?: boolean
  objectId?: number
  avatar?: string
  name: Array<string>
  address: string
  distance?: number
  specialist?: boolean
  isActive?: boolean
  locationTitle?: string
  type: string
  detailsLink?: string
  directionsLink?: string
  onActive: (id: number) => void
  doctorCustomReviewLink?: string | null
  doctorCustomReviewText?: string | null
  doctorGoogleReviewLink?: string | null
  doctorReviewId?: string | null
  doctorReviewType?: string | null
  locationPhone?: string | null
  requestCallbackDoctorComponent?: JSX.Element
  slug?: string
}

const defaultProps = {
  expandable: false,
  objectId: null,
  avatar: null,
  distance: null,
  specialist: false,
  isActive: false,
  locationTitle: null,
  detailsLink: null,
  directionsLink: null,
}

const MListItem = (props: Props) => {
  const settings = useSettings()
  const siteInfo = useSiteInfo()
  const router = useRouter()
  const appContext = useContext(AppContext)

  const {
    objectId,
    avatar,
    name,
    address,
    distance,
    isActive,
    type,
    detailsLink,
    onActive,
    locationPhone,
    doctorCustomReviewText,
    doctorGoogleReviewLink,
    doctorReviewType,
    doctorReviewId,
    doctorCustomReviewLink,
    requestCallbackDoctorComponent,
    slug,
  } = props

  const { t } = useTranslation('common')

  const pushSegment = (
    event: string,
    isDirections: boolean = false,
    callback?: Function
  ) => {
    if (!window.analytics || siteInfo?.iso !== 'en-US') {
      return
    }
    if (isDirections) {
      window.analytics.track(
        event,
        {
          doctor_name: props.name.length ? props.name[0] : '',
          specialist_doctor: props.specialist,
          doctor_clinic: props.locationTitle,
          doctor_address: props.address,
          distance_to_user: props.distance,
        },
        callback
      )
    } else {
      window.analytics.track(
        event,
        {
          doctor_name: props.name.length ? props.name[0] : '',
          specialist_doctor: props.specialist,
          doctor_clinic: props.locationTitle,
          doctor_address: props.address,
          doctor_distance: props.distance,
          doctor_has_avatar: !!props.avatar,
        },
        callback
      )
    }
  }

  const handleClick = () => {
    pushSegment('Doctor Selected')

    if (onActive instanceof Function) {
      onActive(objectId ?? 0)
    }
  }

  const handleViewDetailsClick = (
    event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    if (props.type === 'doctor') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'doctor_details',
          doctor_name: name.length ? name[0] : '',
          doctor_distance: distance,
          doctor_id: objectId,
        },
      })
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: 'location_details',
          doctor_name: name.length ? name[0] : '',
          doctor_distance: distance,
          doctor_id: objectId,
        },
      })
    }

    event.stopPropagation()
    event.preventDefault()
    if (detailsLink) {
      router.push(detailsLink, undefined, { shallow: true })
    }
  }

  const handlePhoneButton = (event: MouseEvent) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'call_doctorOrLocation',
        doctor_name: name?.[1],
        doctor_distance: distance,
        doctor_id: objectId,
      },
    })
    pushSegment('Doctor Called', false)
  }

  if (siteInfo.isUS) {
    return (
      <div
        className={classNames(
          'provider-listing border-b last:border-b-0 first:border-t font-body font-base font-semibold transition-colors duration-200 ease-out md:flex',
          {
            'border-transparent': isActive,
            'border-gray-200': !isActive,
            'bg-[#cbecf7]':
              props.isActive && siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
            'bg-pink-300':
              props.isActive && siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
          }
        )}
        data-drslug={slug}
      >
        <div
          className="flex md:px-2.5 py-6 outline-none cursor-pointer flex-1"
          onClick={handleClick}
        >
          <div className="flex gap-8">
            {settings.list.avatar.includes(type) && (
              <div>
                <div className="w-[83px] h-[83px] lg:w-[6.6vw] lg:h-[6.6vw] bg-gray-200 rounded-full flex-shrink-0 overflow-hidden relative mx-auto">
                  {avatar && (
                    <Image
                      alt={name.length ? name[0] : ''}
                      src={avatar}
                      fill
                      style={{ objectFit: 'cover' }}
                    />
                  )}
                </div>
                <button
                  className="provider-profile md:hidden underline text-primary-dark-teal mt-5 font-semibold text-sm min-w-[95px]"
                  onClick={(e) => handleViewDetailsClick(e)}
                >
                  {t('view-profile')}
                </button>
              </div>
            )}
            <div className="flex-grow">
              <h3 className="text-2xl mb-2">{name?.[0]}</h3>
              <p className="text-md">{name?.[1]}</p>
              <div className="flex items-center gap-x-2.5 font-normal text-neutral-black mt-6 text-sm md:text-md">
                <ASvg
                  className="w-5 h-5 text-teal stroke-current"
                  svg={SvgLocationIconUS}
                />
                <address className="not-italic">
                  {props.distance || '< 1'} {settings.unit.label} • {address}
                </address>
              </div>
              <div className="lg:mt-2 font-normal text-sm md:text-md">
                {doctorReviewId && (
                  <MReviews
                    doctorCustomReviewLink={doctorCustomReviewLink}
                    doctorCustomReviewText={doctorCustomReviewText}
                    doctorGoogleReviewLink={doctorGoogleReviewLink}
                    doctorReviewId={doctorReviewId}
                    doctorReviewType={doctorReviewType}
                    location={appContext?.state.location}
                  />
                )}
              </div>
              <button
                className="provider-profile hidden md:block underline text-primary-dark-teal mt-5 font-semibold"
                onClick={(e) => handleViewDetailsClick(e)}
              >
                {t('view-profile')}
              </button>
            </div>
          </div>
        </div>
        {locationPhone && (
          <div className="consultation px-5 py-12 bg-neutral-100 md:w-[265px] flex flex-col items-center gap-[23px]">
            <h4 className="text-lg font-semibold">{t('set-consultation')}</h4>
            <p className="text-sm font-normal text-center">
              {t('contact-this-office')}
            </p>
            <AButton
              type="a"
              className="w-full consultation-phone"
              title={t('contact') ?? ''}
              size="lg"
              href={`tel:${locationPhone}`}
              onClick={(event) => handlePhoneButton(event)}
            >
              <span>{locationPhone}</span>
            </AButton>

            {requestCallbackDoctorComponent}
          </div>
        )}
        <style jsx>{styles}</style>
      </div>
    )
  }

  /* eslint-disable @next/next/no-img-element */
  return (
    <div
      className={classNames(
        'border-b last:border-b-0 font-body font-base font-semibold transition-colors duration-200 ease-out',
        {
          'border-transparent': props.isActive,
          'border-gray-200': !props.isActive,
          'bg-[#cbecf7]':
            props.isActive && siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
          'bg-pink-300':
            props.isActive && siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
        }
      )}
    >
      <div
        className="flex px-8 py-6 outline-none cursor-pointer"
        onClick={handleClick}
      >
        {settings.list.avatar.includes(props.type) && (
          <div className="w-[72px] h-[72px] bg-gray-200 rounded-full flex-shrink-0 overflow-hidden">
            {props.avatar && (
              <img
                className="w-full h-full object-cover"
                alt={props.name.length ? props.name[0] : ''}
                src={props.avatar}
              />
            )}
          </div>
        )}
        <div
          className={classNames('flex-grow', {
            'mx-4': settings.list.avatar.includes(props.type),
            'mr-4': !settings.list.avatar.includes(props.type),
          })}
        >
          {props.name.map((v, i) => {
            return (
              <div key={i} className="text-gray-900">
                {v}
              </div>
            )
          })}
          <div
            className={classNames({
              'text-gray-800': !props.isActive,
              'text-gray-900': props.isActive,
            })}
            dangerouslySetInnerHTML={{
              __html: props.address?.replace(/\n/, '<br>'),
            }}
          />
        </div>
        <div
          className={classNames('flex-shrink-0 flex flex-col items-end', {
            'text-gray-800': !props.isActive,
            'text-gray-900': props.isActive,
          })}
        >
          {props.distance !== null && settings.search === 'geocoding' && (
            <div>
              {props.distance || '< 1'} {settings.unit.label}
            </div>
          )}
          {props.specialist && (
            <ASvg
              svg={SvgSpecialistIcon}
              className={classNames('w-8 h-8 fill-current mt-5', {
                'text-primary-teal': siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
              })}
            />
          )}
          {props.detailsLink && (
            <a
              href={props.detailsLink}
              className={classNames(
                `m-list-item__details-link group uppercase text-sm font-bold mt-4 flex items-center m-list-item__details-link--${siteInfo?.iso}`,
                {
                  'text-primary-teal':
                    siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                  'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                }
              )}
              onClick={(e) => handleViewDetailsClick(e)}
            >
              <span>{t('view-details')}</span>
              <ASvg
                className="transform group-hover:translate-x-1 transition-transform duration-200 w-5 h-5 stroke-current ml-2"
                svg={SvgArrowRightIcon}
              />
            </a>
          )}
        </div>
      </div>
      <style jsx>{styles}</style>
    </div>
  )
}

MListItem.defaultProps = defaultProps

export { MListItem }

const styles = css`
  .m-list-item__specialist-icon {
    height: 16px;
    width: 35px;
  }

  .m-list-item__details-link {
    max-width: 55px;
  }

  .m-list-item__details-link--de-DE,
  .m-list-item__details-link--es-ES,
  .m-list-item__details-link--es,
  .m-list-item__details-link--fr-FR,
  .m-list-item__details-link--it-IT,
  .m-list-item__details-link--pt-PT,
  .m-list-item__details-link--pt-BR,
  .m-list-item__details-link--nl-NL,
  .m-list-item__details-link--de-CH {
    max-width: 80px;
  }

  .m-list-item__details-link--ko-KR,
  .m-list-item__details-link--ja-JP,
  .m-list-item__details-link--fr-BE,
  .m-list-item__details-link--fr-CA,
  .m-list-item__details-link--sv-SE {
    max-width: 120px;
  }
`
